@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
@import "./additional-styles/utility-patterns.scss";
@import "./additional-styles/range-slider.scss";
@import "./additional-styles/toggle-switch.scss";
@import "./additional-styles/theme.scss";
@import "./additional-styles/our-styles.scss";

body {
  margin: 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

html {
  scroll-behavior: smooth;
}

::selection {
  background-color: #184ddc !important;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-popup-close-button {
  color: #fff !important;
  font-size: 22px;
  right: 15px !important;
  top: 10px !important;
}

.mapboxgl-popup-content {
  border-radius: 5px !important;
  padding: 1.5rem !important;
  background-color: rgb(31, 41, 55) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgb(31, 41, 55) !important;
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  margin-top: -50px;
}

.offline-notification {
  background: #ff0000;
  text-align: center;
  position: sticky;
  transition: transform 0.7s;
  transform: translateY(0) translateX(0);
  top: 0;
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}

.markdown-content {
  & > p,
  & > ul,
  & > ol {
    margin-bottom: 1em;
  }

  & ul {
    list-style: disc;
    padding-left: 1em;
  }

  & ol {
    list-style: decimal;
    padding-left: 1em;
  }

  & > p:last-child,
  & > ul:last-child,
  & > ol:last-child {
    margin-bottom: 0;
  }

  & ul > li > ol,
  & ol > li > ul {
    padding-left: 2em;
  }

  & > ul > li,
  & > ol > li {
    margin-bottom: 0.5em;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-scroll {
  overflow: hidden !important;
}

/* Общий стиль для скроллбара */
.mantine-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.small-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 6px;
  }
}
